import { AutoForm, AutoFormInputField, Button } from "@9amhealth/shared";
import styled from "@emotion/styled";
import React, { useEffect } from "react";
import translate from "src/lib/translate";
import { useBloc } from "src/state/state";
import { z } from "zod";
import AsyncContent from "../AsyncContent/AsyncContent";
import CopyText from "../CopyText/CopyText";
import Qrcode from "../Qrcode/Qrcode";
import ErrorBox from "../StyledComponents/ErrorBox";
import Translate from "../Translate/Translate";
import TwoFactorAuthenticationBloc, {
  TwoFactorAuthenticationController
} from "./TwoFactorAuthenticationBloc";

const MfaContent = styled.div`
  padding: 0.2rem 1rem 1.2rem;

  h5 {
    margin-bottom: 0.8rem;
  }

  .qr-code {
    width: 60%;
    margin: 0 0 1.5rem;
  }

  hr {
    margin: 2rem 0;
  }
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  margin: 0.5rem 0 0.6rem;
`;

const ErrorContent = styled.div`
  h5 {
    margin-bottom: 0.8rem;
    color: var(--color-danger);
    font-size: 1.6rem;
    font-family: var(--font-family-serif);
  }

  hr {
    margin: 2rem 0;
  }
`;

const TwoFactorAuthenticationSetup = () => {
  const [
    { setupStep, enabled, secret, loading, error },
    { startSetup, activateSecret, startDisable, removeCredential, checkUser }
  ] = useBloc(TwoFactorAuthenticationBloc, {
    create: () => TwoFactorAuthenticationController
  });

  const handleActivateSubmit = (data: { token?: string }) => {
    const { token } = data;
    if (!loading && token) {
      void activateSecret(token);
    }
  };

  const handleDisableSubmit = (data: { token?: string }) => {
    const { token } = data;
    if (!loading) {
      void removeCredential(token);
    }
  };

  useEffect(() => {
    void checkUser();
  }, []);

  return (
    <MfaContent style={{ marginBottom: "15rem" }}>
      {enabled && (
        <>
          {setupStep === "start" && (
            <>
              {loading ? (
                <AsyncContent
                  check={[false]}
                  ratio={2}
                  style={{ marginTop: "1.4rem" }}
                />
              ) : (
                <>
                  <h5>
                    <Translate msg="mfa.enabled" />
                  </h5>
                  <Actions>
                    <Button
                      theme="charcoal"
                      size="small"
                      onPress={() => startDisable()}
                    >
                      <Translate msg="mfa.disable" />
                    </Button>
                  </Actions>
                </>
              )}
            </>
          )}

          {setupStep === "disable" && (
            <>
              <h5>
                <Translate msg="mfa.verificationTitle" />
              </h5>
              <p>
                <Translate msg="mfa.verificationCode" />
              </p>
              <AutoForm
                schema={z.object({
                  token: z.string().min(6).max(6)
                })}
                onSubmit={handleDisableSubmit}
              >
                <AutoFormInputField
                  label={translate("code")}
                  required
                  mask={{
                    mask: "000000"
                  }}
                  autoComplete="one-time-code"
                  name="token"
                />
                {error && (
                  <>
                    <nine-spacer s="xs" />
                    <ErrorBox>{error}</ErrorBox>
                    <nine-spacer s="xs" />
                  </>
                )}
                <Actions>
                  <Button
                    theme="charcoal"
                    disabled={loading}
                    size="small"
                    type="submit"
                  >
                    <Translate msg="mfa.disable" />
                  </Button>
                </Actions>
              </AutoForm>
            </>
          )}
        </>
      )}
      {!enabled && (
        <>
          {setupStep === "start" && (
            <>
              {loading ? (
                <AsyncContent
                  check={[false]}
                  ratio={2}
                  style={{ marginTop: "1.4rem" }}
                />
              ) : (
                <>
                  <h5>
                    <Translate msg="mfa" />
                  </h5>
                  <p>
                    <Translate msg="mfa.description" />
                  </p>
                  <Actions>
                    <Button size="small" theme="charcoal" onPress={startSetup}>
                      <Translate msg="mfa.enable" />
                    </Button>
                  </Actions>
                </>
              )}
            </>
          )}
          {setupStep === "error" && (
            <ErrorContent>
              <h5>
                <Translate msg="error.generic.short" />
              </h5>
              <p>
                <Translate msg="mfa.error" />
              </p>
              <Actions>
                <Button onPress={startSetup} theme="charcoal" size="small">
                  <Translate msg="retry" />
                </Button>
              </Actions>
            </ErrorContent>
          )}
          {setupStep === "verify" && (
            <>
              <h5>
                <Translate msg="mfa.scanQR" />
              </h5>
              <p>
                <Translate msg="mfa.scanQRHelper" />
              </p>
              <AsyncContent
                check={[secret]}
                width="calc(60% + 1.5rem)"
                ratio={1}
              >
                {secret && (
                  <Qrcode
                    text={`otpauth://totp/App?secret=${secret}&issuer=9amHealth`}
                    hideLogo
                    frame
                  />
                )}
              </AsyncContent>
              <p>
                <Translate msg="mfa.manualVerification" />
                <CopyText text={secret ?? ""}>
                  {secret ?? ".........."}
                </CopyText>
              </p>
              <hr />
              <h5>
                <Translate msg="mfa.verificationTitle" />
              </h5>
              <p>
                {" "}
                <Translate msg="mfa.verificationCode" />
              </p>
              <AutoForm
                schema={z.object({
                  token: z.string().min(6).max(6)
                })}
                onSubmit={handleActivateSubmit}
              >
                <AutoFormInputField
                  label={translate("code")}
                  required
                  mask={{
                    mask: "000000"
                  }}
                  autoComplete="one-time-code"
                  name="token"
                />
                {error && (
                  <>
                    <nine-spacer s="xs" />
                    <ErrorBox>{error}</ErrorBox>
                    <nine-spacer s="xs" />
                  </>
                )}
                <Actions>
                  <Button
                    theme="charcoal"
                    disabled={loading}
                    size="small"
                    type="submit"
                  >
                    <Translate msg="continue" />
                  </Button>
                </Actions>
              </AutoForm>
            </>
          )}
        </>
      )}
    </MfaContent>
  );
};

export default TwoFactorAuthenticationSetup;
